<template>
  <div class="menuV4 scrollbar" ref="navlist">
    <span
      v-for="(value, index) in tabTitleList"
      :key="index"
      class="menuV4_item"
      :class="[{ 'menuV4_item-active': activeIndex == index }]"
      v-debounce="[
        'click',
        () => {
          switchTab(index, $event, value.disable);
        },
      ]"
    >
      <slot></slot>
      {{ value.tabTitle }}
    </span>
  </div>
</template>

<script>
export default {
  name: "menuV4",
  props: {
    isScroll: {
      type: Boolean,
      default: false,
    },
    defIndex: {
      type: Number,
      default: 0,
    },
    initData: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  data() {
    return {
      tabTitleList: [],
      activeIndex: this.defIndex,
      initX: "0",
      navWidth: [],
      tapWidth: "",
      smoothFlag: true,
    };
  },
  watch: {
    $route: {
      handler(val, oldval) {
        this.smoothFlag = false;
        this.scrollTab(this.activeIndex);
      },
      // 深度观察监听
      deep: true,
    },
    isScroll() {
      this.updeteTab();
    },
    defIndex() {
      this.updeteTab();
    },
    initData: {
      handler() {
        this.updeteTab();
      },
      deep: true,
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.$slots.default && this.updeteTab(this.$slots.default);
    });
  },
  methods: {
    updeteTab: function () {
      this.$nextTick(() => {
        this.tabTitleList = [];
        this.activeIndex = this.defIndex;
        this.initTab([...this.$slots.default]);
      });
    },
    initTab: function (slot) {
      for (let i = 0; i < slot.length; i++) {
        let slotTag = slot[i].tag;
        if (
          typeof slotTag == "string" &&
          slotTag.indexOf("nut-tab-panel") != -1
        ) {
          let attrs = slot[i].data.attrs;
          let item = {
            tabTitle: attrs["tab-title"] || attrs["tabTitle"],
            disable: attrs.disable === false,
            iconUrl: attrs["iconUrl"] || attrs["icon-url"],
            tabSlot: attrs["tab-slot"] || attrs["tabSlot"] || "",
            badge: attrs["badge"] || false,
          };
          this.tabTitleList.push(item);
        }
      }
      this.$nextTick(() => {
        let tapWidth = "";
        let navWidth = [];
        let domList = this.$refs.navlist.querySelectorAll(".menuV4_item");
        domList.forEach((val) => {
          navWidth.push(val.offsetWidth);
        });
        //所有子组件长度
        this.navWidth = navWidth;
        this.scrollTab(this.activeIndex);
      });
    },
    findParent(event, myclass) {
      let parentCpt = event.target;
      let flag = 0; //避免死循环
      while (parentCpt && flag < 10) {
        flag++;
        if (parentCpt.className && parentCpt.className === myclass) {
          break;
        }
        parentCpt = parentCpt.parentNode;
      }
      return parentCpt;
    },
    switchTab: function (index, event, disable) {
      if (!disable) {
        this.smoothFlag = true;
        this.activeIndex = index;
        this.scrollTab(index);
        this.$emit("tab-switch", index, event);
        this.$emit("tabSwitch", index, event); //兼容以前驼峰法命名
      }
    },
    scrollTab(index) {
      //偏移量
      let sum = 0;
      this.navWidth.forEach((val, idx) => {
        if (idx >= this.activeIndex) {
          return;
        }
        sum += val;
      });
      this.initX = sum;
      //总长度
      let tapWidth = "";
      tapWidth = this.$refs.navlist.offsetWidth;
      this.tapWidth = tapWidth / 2 - this.navWidth[this.activeIndex] / 2;
      if (this.$refs.navlist.scroll) {
        this.$refs.navlist.scroll(this.initX - this.tapWidth, 0, 0);
      }
    },
  },
};
</script>
<style lang="scss" scope>
@include b(menuV4) {
  position: relative;
  display: flex;
  height: 44px;
  width: 100%;
  background: #fff;
  overflow: hidden;
  overflow-x: scroll;
  @include e(item) {
    position: relative;
    display: inline-block;
    height: 100%;
    box-sizing: border-box;
    padding: 0 24px;
    white-space: nowrap;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    display: flex;
    align-items: center;
    justify-content: center;
    @include m(active) {
      color: #f46a17 !important;
      &:after {
        position: absolute;
        bottom: 9px;
        content: "";
        width: 16px;
        height: 2px;
        background: #eb6100;
        border-radius: 1px;
      }
    }
  }
}
</style>
