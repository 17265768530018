<template>
  <div class="goodsListV3">
    <div class="goodsListV3_head box-p-t-12">
      <div class="goodsListV3_head_user">
        <img class="goodsListV3_head_user_avatar" :src="orderInfo.avatarUrl" />
        <span class="goodsListV3_head_user_name">{{ orderInfo.nickName }}</span>
      </div>
      <div class="goodsListV3_head_tip">{{ orderInfo.statusName }}</div>
    </div>
    <div class="l-flexh l-flexci-c l-flexm-b" style="width: 100%">
      <div class="l-flexh l-flexci-s box-m-t-12">
        <div
          class="goodsListV3_goods box-m-r-9"
          v-for="(goods, index) in orderInfo.goodList"
          :key="index"
        >
          <img class="goodsListV3_goods_img" :src="goods.cover" />
          <div class="goodsListV3_goods_title">{{ goods.title }}</div>
          <div class="goodsListV3_goods_num">x{{ goods.total }}</div>
        </div>
      </div>
      <div class="goodsListV3_total">
        <span class="goodsListV3_total_price">
          ¥{{ orderInfo.order_amount }}</span
        >
        <span class="goodsListV3_total_num"> 共 {{ orderInfo.total }} 件</span>
      </div>
    </div>
    <div class="goodsListV3_foot box-m-t-18 box-m-b-16">
      <span class="goodsListV3_foot_time">
        订单时间：{{ orderInfo.create_time }}
      </span>
      <div class="goodsListV3_foot_btn">
        <button
          v-if="orderInfo.store_status == 2 && orderInfo.delivery_status == 0"
          @click="showCode"
        >
          <img
            class="goodsListV3_foot_btn_img"
            src="https://api.mengjingloulan.com/storage/uploads/20211120/9e643e2aee466f9a7ffca89f5efccce3.png"
          />
          <span>提货码</span>
        </button>
        <button class="btn" @click="showSetExpress" v-if="isExpressGoods && orderInfo.status == 1 && orderInfo.can_express == 1">
              快递发货
        </button>
        <button class="goodsListV3_foot_btn-active" @click="goDetail">
          查看详情
        </button>
      </div>
      
    </div>
    
  </div>
</template>

<script>
/**
 * 店铺订单列表
 */
export default {
  name: "goodsListV3",
  props: {
    orderInfo: {
      type: Object,
      default() {
        return {
          user_id: 111,
          avatarUrl:
            "https://thirdwx.qlogo.cn/mmopen/vi_32/uDR31SeFYF4RoDzLbx0P828P1F1BFiaGqPfp0W9TjRJQnKoU0NIBRiac28Iq65OjJu4HvYAFHVQA4EibS8bZ0ItyA/132",
          create_time: "2021-11-22",
          delivery_status: 2,
          flow_up_code_url:
            "http://api.rsck.group/storage//mallgoods/181-flow.png",
          goodList: [
            {
              cover:
                "http://api.rsck.group/storage/uploads/20210824/5f55398b7baed67c01eba65348c1e43d.jpeg",
              title: "2222",
              total: 1,
            },
            {
              cover:
                "http://api.rsck.group/storage/uploads/20210824/5f55398b7baed67c01eba65348c1e43d.jpeg",
              title: "2222",
              total: 2,
            },
            {
              cover:
                "http://api.rsck.group/storage/uploads/20210824/5f55398b7baed67c01eba65348c1e43d.jpeg",
              title: "2222",
              total: 3,
            },
          ],
          id: 2329,
          nickName: "游戏匠😽",
          order_amount: "12121.00",
          site_point_status: 2,
          status: 2,
          statusName: "客户已收货",
          store_id: 29,
          store_status: 2,
          total: 1,
        };
      },
    },
    isExpressGoods: {
      type: Boolean,
      default:true
    },
  },
  data() {
    return {
      show: false,
    }
  },
  methods: {
    showCode() {
      this.$emit("showCode", this.$props.orderInfo);
    },
    goDetail() {
      this.$emit("goDetail", this.$props.orderInfo);
    },
    showSetExpress() {
      this.$emit('setExpress')
    }
  },
};
</script>

<style lang="scss" scoped>
@include b(goodsListV3) {
  width: 375px;
  height: 234px;
  background: #ffffff;
  border-radius: 10px;
  box-sizing: border-box;
  padding: 0 15px;
  
  @include e(head) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include e(user) {
      display: flex;
      align-items: center;
      @include e(avatar) {
        width: 28px;
        height: 28px;
      }
      @include e(name) {
        margin-left: 8px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
      }
    }
    @include e(tip) {
      font-size: 14px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #f46a17;
    }
  }
  @include e(goods) {
    display: flex;
    flex-direction: column;
    // width: 74px;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    position: relative;
    @include e(img) {
      width: 74px;
      height: 74px;
      border-radius: 4px;
    }
    @include e(title) {
      width: 100%;
      text-overflow: ellipsis;
      word-break: break-all;
      white-space: nowrap;
      color: #333333;
      margin: 7px 0;
    }
    @include e(num) {
      color: #939393;
    }
  }
  @include e(total) {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    @include e(price) {
      font-size: 16px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #333333;
    }
    @include e(num) {
      margin-top: 9px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #939393;
    }
  }
  @include e(foot) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include e(time) {
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #939393;
    }
    @include e(btn) {
      width: 175px;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      & > button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 87px;
        height: 36px;
        border-radius: 18px;
        border: 1px solid #d1d1d1;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        background: transparent;
      }
      @include e(img) {
        width: 16px;
        height: 16px;
      }
      @include m(active) {
        margin-left: 7px;
        width: 82px !important;
        border: 1px solid #f46a17 !important;
        color: #f46a17 !important;
      }
    }
  }
}



</style>
