<template>
    <div class="orderList">
        <div class="orderList_search_box">
            <searchV1
                    class="orderList_search_item"
                    v-model="keyword"
                    @search="searchOrder"
                    @clear="searchOrder"
            ></searchV1>
        </div>
        <div class="orderList_head">
            <menuV4
                    @tab-switch="tabSwitch"
                    :isShowLine="false"
                    :lineWidth="88"
                    :line-width="0"
                    :contentShow="false"
                    :is-scroll="true"
                    :def-index="0"
                    :initData="cataLogs"
            >
                <nut-tab-panel
                        v-for="(item, index) in cataLogs"
                        :key="index"
                        :tab-title="item.title"
                >
                </nut-tab-panel>
            </menuV4>
        </div>
        <div class="orderList_body">
            <scrollview @onRefresh="onRefresh" @onReload="onReload">
                <template v-if="list.length > 0">
                    <div class="box-m-b-10" v-for="(item, index) in list" :key="index">
                        <goodsListV3
                                :orderInfo="item"
                                @showCode="onShowCode"
                                @goDetail="goDetail"
                                @setExpress="setExpress(item)"
                        ></goodsListV3>
                    </div>
                </template>
            </scrollview>
        </div>

        <nut-popup v-model="show"  :closeable="true" position="bottom" :style="{ height: '80%' }"
                   :close-on-click-overlay="false" @close="initExpressInfo">
      <div class="confirm-window">
          <div class="title">商品发货</div>
          <template >
              <div class="content">
                <div
                :class="[chooseGoodsId.includes(goods.goods_id) ? 'choose-active' : '']"
                  class="choose-goods-list box-m-r-9"
                  v-for="(goods, index) in showGoodsList"
                  :key="index"
                  @click="chooseGoods(goods)"
                  v-if="goods.has_express != 1"
              >
                  <img class="goods-image" :src="goods.cover" />
                  <div class="goods-info">
                    <div class="goods-title">{{ goods.title }}</div>
                    <div class="goods-number">x{{ goods.total }}</div>
                  </div>
                </div>
              </div>
              <div class="actions">
                <div style="padding:10px;margin: 5px;">
                  <select class="selectClass" v-model="chooseExpressId" style="width:100%">
                    <option :value="0">请选择发送的快递</option>
                    <option v-for="(item,itemIndex) in expressList" :key="itemIndex" :value="item.value">{{ item.label }}</option>
                  </select>
                </div>
                <div style="padding:10px;margin:5px">
                  <nut-textinput
                    v-model="expressOrderSN"
                    label="快递单号："
                    placeholder="请输入快递单号"
                    :clearBtn="true"
                    :disabled="false"
                  />
                </div>
                <div class="actions" style="padding:10px;margin: 5px;">
                  <nut-button
                    block
                    shape="circle"
                    @click="confirmSetExpressInfo"
                  >
                    确认发货
                  </nut-button>
                </div>
              </div>
          </template>
      </div>
      </nut-popup>
    </div>
</template>

<script>
  import searchV1 from '@T/community/search/searchV1'
  import menuV4 from '@T/community/menu/menuV4'
  import goodsListV3 from '@T/community/goodsList/goodsListV3'
import { CompatSource } from 'webpack-sources'

  export default {
    name: 'order_list',
    components: {goodsListV3, menuV4, searchV1},
    data () {
      return {
        cataLogs: [],
        list: [],
        keyword: '',
        params: {
          page: 1,
          page_size: 10,
        },
        selectIndex: -1,
        expressList: [],
        order_type: 1,
        show: false,
        chooseOrderId:0,
        showGoodsList:[],
        chooseGoodsId: [],
        chooseExpressId: 0,
        expressOrderSN:''
      }
    },
    created () {

      let query = this.$route.query
      this.order_type = query.order_type
      if(this.order_type == 2){
        // document.title = '一件代发订单'
        document.title = '一件包邮订单'
      }else{
        document.title = '社区订单'
      }
      this.getCataLog()
      this.getOrderList()
      this.getExpressList()
    },
    methods: {
      onRefresh (done) {
        this.list = []
        this.params.page = 1
        this.getOrderList().finally(() => {
          done()
        })
      },
      /**
       * step 当前加载结束
       * over 没有更多数据了
       */
      onReload (step, over) {
        console.log('上拉加载')
        this.params.page += 1
        this.getOrderList().finally(() => {
          if (this.list.length < this.params.page * this.params.page_size) {
            over()
          } else {
            step()
          }
        })
      },
      tabSwitch (index, event) {
        this.selectIndex = this.cataLogs[index].status
        this.params.page = 1
        this.list = []
        this.getOrderList()
      },
      //搜索订单
      searchOrder () {
        this.params.page = 1
        this.list = []
        this.getOrderList()
      },
      async getCataLog () {
        try {
          //this.isHasMore=false;
          let query = await this.$api.community.stores.orderCatalog()
          this.cataLogs = query.data
          //console.log(this.cataLogs)
          this.$forceUpdate()
        } catch (error) {
          this.$toast.fail(error.message)
        }
      },
      getExpressList() {
        this.$api.community.stores.expressList().then(response => {
          this.expressList = response.data
        })
      },
      async getOrderList () {
        try {
          let params = {
            ...this.params,
            keyword: this.keyword,
            status: this.selectIndex,
            order_type:this.order_type
          }
          let query = await this.$api.community.stores.mallOrder(params)
          if (query.data.length == 0) {
            return
          }
          this.list = [...this.list, ...query.data]
        } catch (error) {
          //
        }
      },
      onShowCode (info) {
        this.$showCodeV1({
          title: '提货码', // 标题
          codeUrl: info.flow_up_code_url, //二维码链接地址
          confirmText: '确定', // 确认文字
          success: () => {
            // 确认执行函数
            console.log('确定')
          },
        })
      },
      goDetail (orderInfo) {
        if (this.$platform.wxsdk.isWechat()) {
          let query = encodeURIComponent(
            JSON.stringify({order_id: orderInfo.id})
          )
          this.$platform.wxsdk.wxRoute({
            type: 'navigateTo',
            url: '/web/stores/order_info?query=' + query,
          })
        } else {
          this.$router.push({
            path: 'order_info',
            query: {order_id: orderInfo.id},
          })
        }
      },
      chooseGoods(goods) {
        let goodsId = goods.goods_id
        if (this.chooseGoodsId.includes(goodsId)) {
          this.chooseGoodsId.forEach((item, index) => {
            this.chooseGoodsId.splice(index, 1)
          })
        } else {
          this.chooseGoodsId.push(goodsId)
        }
      },
      initExpressInfo() {
        this.showGoodsList = []
        this.chooseGoodsId = []
        this.chooseExpressId = 0
        this.expressOrderSN = ''
        this.chooseOrderId = 0
      },
      setExpress(record) {
        this.showGoodsList = record.goodList
        this.chooseOrderId = record.id
        this.show = true
      },
      confirmSetExpressInfo() {
        let expressId = this.chooseExpressId
        let expressOrderSN = this.expressOrderSN
        if (!expressId) {
          this.$notify.warn('请选择发货快递');
          return
        }
        if (!expressOrderSN) {
          this.$notify.warn('请输入快递单号')
          return
        }
        if (this.chooseGoodsId.length == 0) {
          this.$notify.warn('请选择商品');
          return
        }

        let data = {
          order_id: this.chooseOrderId,
          goods_ids: this.chooseGoodsId,
          express_id: this.chooseExpressId,
          express_order_sn:this.expressOrderSN
        }

        this.$api.community.stores.setOrderExpress(data).then(() => {
          this.$notify.success('快递信息设置成功')
          this.show = false
          this.initExpressInfo()
          this.searchOrder()

        }).catch(error => {
          this.$notify.warn(error.message);
        })
      }
    },
  }
</script>

<style lang="scss" scoped>
    @include b(orderList) {
        width: 100vw;
        height: 100vh;
        background: #fff;
        position: relative;
        display: flex;
        flex-direction: column;
        @include e(search_box) {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-shrink: 0;
            width: 100%;
            height: 44px;
        }
        @include e(search_item) {
            width: 345px;
            height: 36px;
        }
        @include e(head) {
            width: 100%;
            height: 44px;
            flex-shrink: 0;
        }
        @include e(body) {
            flex-grow: 1;
            background: #f6f6f6;
            box-sizing: border-box;
            padding: 12px 0;
        }
    }


    .confirm-window {
    display: flex;
    flex-direction: column;

    .title {
        flex: 1;
        font-size: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: rgba(0, 0, 0, 0.6);
        padding: 15px 0;
    }

    .content {
        font-size: 16px;
        color: #333;
        padding: 20px 10px;
        line-height: 25px;

        .choose-goods-list{
          display:flex;
          flex-direction:row;
          padding: 5px;
          border-radius: 5px;
          border: #ccc dashed 1px;


          .goods-image{
            width:50px;
          }

          .goods-info{
            flex:1;
            display:flex;
            flex-direction:column;
            margin-left:10px
          }
        }

        .choose-active{
          background: #108ee9;
          color:#fff;
        }
    }

}

.selectClass{
  height: 30px;
  line-height: 30px;
  font-size: 14px;
}
</style>
